import React, { useState } from "react"
import { navigate } from "gatsby"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { StripeForm, Button, Text, Box, PoweredByStripe } from "@/components/"
import useOptions from "./useOptions"
import createUser from "@/api/createUser"
import tokenAuth from "@/api/tokenAuth"
import createSubscription from "@/api/createSubscription"
import updatePaymentMethod from "@/api/updatePaymentMethod"
import useStore, { paymentInfo } from "@/store/"

export const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()
  const [error, setError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const plan = useStore(state => state.plan)
  const user = useStore(state => state.user)

  const handleSubmit = async event => {
    event.preventDefault()
    setSubmitting(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardElement)
    const result = await stripe.createToken(cardElement)

    if (result.error) {
      setError(result.error)
      return
    }

    try {
      // create user
      const values = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
      }
      await createUser(values)
      await tokenAuth({ email: values.email })

      // create stripe user
      const stripeToken = result.token.id
      await updatePaymentMethod(stripeToken)

      // // set up payment
      const planInfo = paymentInfo[plan]

      await createSubscription(planInfo)
      setSubmitting(false)
      navigate("/skill-assessment")
    } catch (error) {
      setError(error)
      setSubmitting(false)
    }
  }

  return (
    <StripeForm onSubmit={handleSubmit}>
      <div
        style={{
          border: "1px solid #E1E0E3",
          padding: "12px",
          borderRadius: "6px",
          marginBottom: "18px",
        }}
      >
        <CardElement options={options} />
      </div>
      {error && (
        <Text level={2} color="alertRed" mb="1.2rem" mt="-1rem">
          {error.message}
        </Text>
      )}
      <Button variant="primary" type="submit" disabled={!stripe || submitting}>
        {submitting ? "Submitting... " : "Pay now"}
      </Button>

      <Box mt="1.2rem">
        <PoweredByStripe />
      </Box>
    </StripeForm>
  )
}
