import React from "react"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, GlobalType, defaultTheme } from "../theme/index"
import { Header, Footer, Box } from "@/components/index"

import "@/theme/fonts.css"

export const Layout = ({
  children,
  headerTheme = "light",
  footerTheme = "dark",
  headerType = "default",
  squeeze = false,
}) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box as="main" height="100%" width="100%">
        <Box
          as="header"
          zIndex="4"
          width="100%"
          position="fixed"
          height="9.6rem"
        >
          <Header headerTheme={headerTheme} headerType={headerType} squeeze={squeeze} />
        </Box>
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <Box flex="1 1 100%">{children}</Box>
          <Box flex="1" as="footer">
            <Footer footerTheme={footerTheme} squeeze={squeeze} />
          </Box>
        </Box>

        <GlobalStyle />
        <GlobalType />
      </Box>
    </ThemeProvider>
  )
}
