import React from "react"
import { Heading, Text, CTA, Box, Width } from "@/components/index"
import { color } from "@/theme/index"

export const PromoBanner = () => {
  return (
    <Box
      bg={color.tourmaline}
      backgroundImage={color.gradient}
      color="white"
      py={["3rem", "6rem", null, null]}
      variant="slopeTopLeft"
    >
      <Width textAlign="center">
        <Box
          width={8 / 12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mx="auto"
        >
          <Heading mb="3.6rem" color={color.thulite}>
            Start your path to fretboard fluency
          </Heading>
          <CTA to="/application" variant="special" mb="1.2rem">
            Apply Today 
          </CTA>
          {/* <Text variant="small">No obligation, cancel anytime.</Text> */}
        </Box>
      </Width>
    </Box>
  )
}
