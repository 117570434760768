import React from "react"
import { IconBase } from "./IconBase"

export const Email = ({
  width = 17,
  height = 14,
  shouldScale = true,
  fill = "#fff",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      shouldScale={shouldScale}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 2.2c0-.88-.72-1.6-1.6-1.6H2.1C1.22.6.5 1.32.5 2.2v9.6c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V2.2zm-1.6 0l-6.4 4-6.4-4h12.8zM8.5 7.8l-6.4-4v8h12.8v-8l-6.4 4z"
        fill={fill}
      />
    </IconBase>
  )
}
