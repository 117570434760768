import React from "react"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

export const Carousel = ({ children }) => {
  const params = {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  }

  return <Swiper {...params}>{children}</Swiper>
}
