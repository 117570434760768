import "focus-visible"
import { css, createGlobalStyle } from "styled-components"
import { breakpoints, fontWeight } from "@/theme/index"

const sansSerifFallback =
  "'-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Roboto', 'sans-serif'"
export const fontFamily = `'Sohne', ${sansSerifFallback}`

export const body = css`
  font-size: 2rem;
  line-height: 1.25;
`

export const body2 = css`
  font-size: 1.6rem;
  line-height: 1.37;
  + p {
    margin-top: 2.4rem;
  }
`

export const body3 = css`
  font-size: 1.8rem;
  line-height: 1.22;
`

export const footer = css`
  font-size: 1.2rem;
  line-height: 1.33;
  letter-spacing: 0.08em;
`

export const bodySmall = css`
  font-size: 1.4rem;
  line-height: 1.14;
`

export const subhead = css`
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.08em;
  opacity: 0.8;
  text-transform: uppercase;
`

export const heading1 = css`
  font-family: "Tiempos Headline";
  font-size: 2.4rem;
  line-height: 1.25;
  font-weight: 600;

  @media (min-width: ${breakpoints[1]}) {
    font-size: 3.2rem;
    line-height: 1.12;
  }
`

export const heading2 = css`
  font-family: "Tiempos Headline";
  font-size: 2.4rem;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: 0.01em;
`

export const headingXL = css`
  font-family: "Tiempos Headline";
  font-size: 4.4rem;
  line-height: 1.25;
  font-weight: 600;

  @media (min-width: ${breakpoints[1]}) {
    font-size: 5rem;
    line-height: 1.12;
  }
`

export const heading3 = css``

export const nav = css`
  font-size: 1.8rem;
  line-height: 1.16;
  letter-spacing: 0.005em;
`

export const footernav = css`
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
`

export const cta = css`
  font-size: 1.5rem;
  font-weight: ${fontWeight.bold};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
`

export const listItem = css`
  font-size: 1.6rem;
  line-height: 1.37;
`

export const listBullet = css`
  content: "•";
  width: 1rem;
  font-size: 1.6rem;
  margin-right: 0.75rem;
  margin-left: -0.3rem;
  display: inline-block;
  color: validGreen;
  text-align: center;
`

export const checkBullet = css`
  content: "✓";
  width: 2.4rem;
  font-size: 1.6rem;
  margin-right: 0.75rem;
  margin-left: -0.3rem;
  display: inline-block;
  color: ${({ theme }) => theme.colors.validGreen};
  text-align: center;
`

export const inputField = css`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.tanzanite};
  font-size: 1.8rem;
  line-height: 1.16;
`

export const labelField = css`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.16;
  color: ${({ theme }) => theme.colors.tanzanite};
`

export const GlobalType = createGlobalStyle`
body {
  ${body};
  font-family: ${fontFamily};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  ${heading1};
}

h2 {
  ${heading2};
}

h3 {
  ${heading3};
} 

p {
  margin: 0;
}


a {
    transition: all 300ms ease;
    color: ${props => props.theme.colors.linkActive};
    cursor: pointer;
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${props => props.theme.colors.neutral};
      border-radius: 4px;
    }
    
    &:focus:not(.focus-visible) {
      outline: none;
      box-shadow: none;
    }
  } 
}
`
