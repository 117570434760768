import React from "react"
import ReactPlayer from "react-player/lazy"
import { Box } from "@/components/index"

export const VideoPlayer = ({
  url = "https://vimeo.com/491014176/8620b7471c",
  ...props
}) => {
  return (
    <Box
      backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
      p="0.8rem"
      height="100%"
      //css={{ maxWidth: "712px", width: "100%" }}
      {...props}
    >
      <Box
        height="100%"
        bg="tourmaline"
        color="white"
        pt="56.25%"
        position="relative"
      >
        <ReactPlayer
          url={url}
          controls
          width="100%"
          height="100%"
          css={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
    </Box>
  )
}
