import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Width,
  Wordmark,
  Instagram,
  Facebook,
  Text,
  Link,
  Email,
} from "@/components/index"

export const Footer = ({ footerTheme = "light" }) => {
  const dark = footerTheme === "dark" ? true : false
  const linkColor = dark ? "white" : "#5127AE"
  return (
    <Box
      display="flex"
      flexDirection="column"
      py="3.6rem"
      bg={dark ? "#150634" : "#F2EDFC"}
      color={dark ? "white" : "tanzanite"}
    >
      <Width display="flex" pb="1.8rem" flexDirection={["column", "row"]}>
        <Box flex="1 1 70%" textAlign={["center", "left"]} mb={["3.6rem", "0"]}>
          <Link to="/">
            <Wordmark width="126" height="21" />
          </Link>
        </Box>
        <Box
          flex="1 1 30%"
          display="flex"
          justifyContent={["space-around", "flex-end"]}
        >
          <Box flex="0 0 15%" mr={["0", "3.6rem"]}>
            <Link to="/affiliates" as="a" color={linkColor}>
              <Text variant="footer" mb="0.8rem">
                AFFILIATES
              </Text>
            </Link>
          </Box>
          <Box flex="0 0 15%" mr={["0", "3.6rem"]}>
            <Text variant="footer" mb="0.8rem">
              CONTACT
            </Text>
            <Link
              to="mailto:contact@learnwithsonora.com"
              as="a"
              color={linkColor}
            >
              <Email fill={linkColor} />
            </Link>
          </Box>

          <Box flex="0 0 15%">
            <Text variant="footer" mb="0.8rem">
              CONNECT
            </Text>
            <Box>
              <Link as="a" to="https://www.instagram.com/sonoraguitarintensive">
                <Instagram fill={linkColor} mr="1.2rem" />
              </Link>
              <Link as="a" to="https://www.facebook.com/sonoraguitarintensive">
                <Facebook fill={linkColor} />
              </Link>
            </Box>
          </Box>
        </Box>
      </Width>
      <Width
        borderTop="1px solid"
        borderColor="#E3DCF1"
        pt="1.4rem"
        display="flex"
        alignItems="center"
        flexDirection={["column", "row"]}
      >
        <Box>
          <Link
            variant="footer"
            color={linkColor}
            to="/privacy-policy"
            mr="2.4rem"
          >
            Privacy Policy
          </Link>
          <Link variant="footer" color={linkColor} to="/terms-and-conditions">
            TERMS & CONDITIONS
          </Link>
        </Box>
        <Box ml={["0", "auto"]} mt={["2.4rem", "0"]}>
          <Text variant="small">©Sonora. All rights reserved.</Text>
        </Box>
      </Width>
    </Box>
  )
}

Footer.propTypes = {
  footerTheme: PropTypes.oneOf(["dark", "light"]),
}
