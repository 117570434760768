import { gql } from "graphql-request"

const mutation = gql`
  mutation(
    $email: String!,
    $password: String!
  ) {
    tokenAuth(
      email: $email,
      password: $password
    ) {
      token
    }
  }
`

export default mutation
