export const identifyHubspotContact = values => {
  // access husbpot
  const _hsq = typeof window !== "undefined" ? window._hsq : []

  console.log("_hsq length ", _hsq, _hsq.length)

  //create new contact in hubspot
  _hsq.push([
    "identify",
    {
      email: values.email,
      firstname: values.first_name,
      lastname: values.last_name,
      phone: values.phone_number,
      id: values.email,
      // skill_level: values.skill_level,
      dev_support: "email and ID were included",
    },
  ])
  _hsq.push(["trackEvent", { id: "Dev Support Test" }])
}

export const inviteHubspotContact = values => {
  // access husbpot
  const _hsq = typeof window !== "undefined" ? window._hsq : []
  //create new contact in hubspot
  _hsq.push([
    "identify",
    {
      email: values.email,
      firstname: values.first_name,
      referrer_id: values.referrer_id,
    },
  ])
  _hsq.push(["trackEvent", { id: "Dev Support Test" }])
}

