import styled from "styled-components"
import {
  color,
  space,
  layout,
  border,
  position,
  typography,
} from "styled-system"
import {
  cta,
  primaryCTA,
  secondaryCTA,
  tertiaryCTA,
  specialCTA,
} from "@/theme/index"

// base button
export const BaseButton = styled.button`
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${typography}
  ${cta}
    -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: all 350ms ease;
    white-space: nowrap;
    display: inline-block;
  `

BaseButton.defaultProps = {
  padding: "1rem 2rem",
  borderRadius: "4px",
  minWidth: "100px",
  border: "0px",
}

/*
  Button 
  variant: primary || secondary || special
*/

export const Button = styled(BaseButton)`
         ${({ variant }) => variant === "primary" && primaryCTA}
         ${({ variant }) => variant === "secondary" && secondaryCTA}
         ${({ variant }) => variant === "tertiary" && tertiaryCTA}
         ${({ variant }) => variant === "special" && specialCTA}

    &:disabled {
           opacity: 0.5;
           pointer-events: none;
           cursor: not-allowed;
         }
       `

Button.defaultProps = {
  variant: "primary",
}
