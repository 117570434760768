import React from "react"
import Img from "gatsby-image"
import { Heading, Text, Box, Link, ArrowGradient } from "@/components/index"

export const MentorCard = ({ name, slug, image, children }) => {
  return (
    <Box>
      <Img alt={name} fluid={image} />
      {slug ? (
        <Link to={`/mentorship/${slug}`} variant="mentor">
          <Box display="flex" alignItems="center">
            <Heading level={3} my="1.2rem">
              {name}
            </Heading>
            <ArrowGradient mt="-.2rem" ml=".2rem" height="2.4rem" />
          </Box>
        </Link>
      ) : (
        <Box display="flex" alignItems="center">
          <Heading level={3} my="1.2rem">
            {name}
          </Heading>
        </Box>
      )}
      <Text level={2}>{children}</Text>
    </Box>
  )
}
