import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import validationSchema from "./validationSchema"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { Box, Button, Text, Label, Input, Heading, Select, Checkbox } from "@/components/index"
import useStore from "@/store/"
import { identifyHubspotContact } from "@/utils/hubspot"

export const CreateAccount = ({
  headerOne = "Get Started", 
  headerTwo = "Create Your Account", 
  cta = "Continue",
  navigateTo = "/payment"
}) => {
  const [isError, setIsError] = useState(false)
  const [log, setLog] = useState(null)
  const location = useLocation()
  const updateUser = useStore(state => state.updateUser)

  // see if there are any parameters in the url
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""

  // determine initial values

  //TODO: refactor this
  const _firstname =
    typeof window !== "undefined" && urlParameters.query.firstname
      ? urlParameters.query.firstname
      : ""
  const _firstname_touched = _firstname ? true : false

  const _lastname =
    typeof window !== "undefined" && urlParameters.query.lastname
      ? urlParameters.query.lastname
      : ""
  const _lastname_touched = _lastname ? true : false

  const _email =
    typeof window !== "undefined" && urlParameters.query.email
      ? urlParameters.query.email
      : ""
  const _email_touched = _email ? true : false

  const _phone =
    typeof window !== "undefined" && urlParameters.query.phone
      ? urlParameters.query.phone
      : ""
  const _phone_touched = _phone ? true : false

  return (
    <Box
      boxShadow="0px 0px 28px rgba(27, 6, 70, 0.2)"
      borderRadius="0.4rem"
      px={["2.4rem", "3.6rem"]}
      py="3.6rem"
      bg="white"
    >
      <Box textAlign="center">
        <Text variant="subhead">{headerOne}</Text>
        <Heading level={2}>{headerTwo}</Heading>
      </Box>
      <Formik
        initialValues={{
          first_name: _firstname,
          last_name: _lastname,
          email: _email,
          phone_number: _phone,
          consent_to_contact: false, // New field for consent

          // skill_level: "",
        }}
        initialTouched={{
          first_name: _firstname_touched,
          last_name: _lastname_touched,
          email: _email_touched,
          phone_number: _phone_touched,
          // skill_level: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          if(values.email) {
            values.email = values.email.toLowerCase()
          }
          try {
            identifyHubspotContact(values)

            // save user info in the store
            updateUser(state => {
              state.user = {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_number: values.phone_number,
                // skill_level: values.skill_level,
              }
            })

            navigate(navigateTo)
            actions.setSubmitting(false)
          } catch (error) {
            actions.setSubmitting(false)
            setIsError(true)
            setLog(error.response)
          }
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting, handleChange }) => (
          <Form name="create-account">
            {/* first name */}
            <Label htmlFor="first_name">
              {touched.first_name && !errors.first_name && (
                <span>First Name*</span>
              )}
              <Input
                type="name"
                name="first_name"
                id="first_name"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="given-name"
                placeholder={"First name*"}
                isvalid={touched.first_name && !errors.first_name}
                error={touched.first_name && errors.first_name}
              />
              {touched.first_name && errors.first_name && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.first_name}
                  </Text>
                </>
              )}
            </Label>

            {/* last name */}
            {/* <Label htmlFor="last_name">
              {touched.last_name && !errors.last_name && (
                <span>Last Name*</span>
              )}
              <Input
                type="last_name"
                name="last_name"
                id="last_name"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="family-name"
                placeholder={"Last name*"}
                isvalid={touched.last_name && !errors.last_name}
                error={touched.last_name && errors.last_name}
              />
              {touched.last_name && errors.last_name && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.last_name}
                  </Text>
                </>
              )}
            </Label> */}

            {/* email */}
            <Label htmlFor="email">
              {touched.email && !errors.email && <span>Email*</span>}
              <Input
                type="email"
                name="email"
                id="email"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="email"
                placeholder={"Email address*"}
                isvalid={touched.email && !errors.email}
                error={touched.email && errors.email}
              />
              {touched.email && errors.email && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.email}
                  </Text>
                </>
              )}
            </Label>

            {/* phone */}
            <Label htmlFor="phone_number">
              {touched.phone_number && !errors.phone_number && (
                <span>Phone Number</span>
              )}


              <Input
                type="phone"
                name="phone_number"
                id="phone_number"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="tel"
                placeholder={"Phone number"}
                isvalid={touched.phone_number && !errors.phone_number}
                error={touched.phone_number && errors.phone_number}
              />
              {touched.phone_number && errors.phone_number && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.phone_number}
                  </Text>
                </>
              )}
            </Label>
            {/* Consent to text/call */}
            <Label htmlFor="consent_to_contact">
              <Checkbox
                name="consent_to_contact"
                id="consent_to_contact"
                checked={touched.consent_to_contact}
                onChange={handleChange}
              />
              <span>I consent to receive texts and calls</span>
            </Label>

            {/* skill level */}
            {/* <Label htmlFor="skill_level">
              {touched.skill_level && !errors.skill_level && (
                <span>Skill Level</span>
              )}

              <Select  name="skill_level" onChange={handleChange}>
                <option value="">Select skill level</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </Select>
              {touched.skill_level && errors.skill_level && (
                <>
                  <Text variant="small" color="alertRed" mb="1.8rem">
                    {errors.skill_level}
                  </Text>
                </>
              )}
            </Label> */}

            {isError && (
              <Text variant="small" color="alertRed" mb="1.8rem">
                {log && log.data && !log.data.createUser
                  ? "Couldn't create account. It's likely your email is already in use."
                  : "Something went wrong"}
              </Text>
            )}

            <Box mt="2.4rem" textAlign="center">
              <Button
                variant="special"
                type="submit"
                disabled={
                  isSubmitting ||
                  !touched.first_name ||
                  // !touched.last_name ||
                  !touched.email ||
                  (touched.name && errors.name) ||
                  // (touched.last_name && errors.last_name) ||
                  (touched.email && errors.email)
                }
              >
                {isSubmitting ? `Submitting...` : cta}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

CreateAccount.propTypes = {
  headerOne: PropTypes.string,
  headerTwo: PropTypes.string,
  cta: PropTypes.string,
  navigateTo: PropTypes.string,
}
