export const fontSizes = [0, 12, 15, 30, 40, 60]
export const space = [0, 20, 30, 40, 50, 60, 120, 180, 256]

// [0, 1, 2] = 640px, 1024px, 1280px
export const breakpoints = ["40em", "64em", "80em"]

export const fontWeight = {
  leicht: 300,
  mager: 400,
  halbfett: 600,
  kraftig: 800,
  semibold: 600,
  bold: 800,
}

export const color = {
  tourmaline: "#1B0646",
  tanzanite: "#5127AE",
  amethyst: "#651FFF",
  ruby: "#D04556",
  thulite: "#F7E7E1",
  agate: "#f2EDFC",
  opal: "#f8FAFD",
  opal_hover: "#E8EEF7",
  white: "#fff",
  black: "#000",
  purpley: "#B3A8CB",
  validGreen: "#0BC3B8",
  neutralBlue: "#3474E0",
  alertRed: "#ED5B4C",
  gradient: "linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)",
}

// light background
export const defaultTheme = {
  ...breakpoints,
  ...fontSizes,
  ...space,
  ...fontWeight,
  colors: {
    ...color,
    accent: color.amethyst,
    linkActive: color.amethyst,
    linkHover: color.amethyst,
    neutral: color.purpley,
  },
}
