import React from "react"
import { IconBase } from "./IconBase"

export const ShoppingIcon = ({
  width = 24,
  height = 24,
  fill = "#651FFF",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79 7.158h1.72a2.372 2.372 0 01-.142-.79 2.365 2.365 0 014.342-1.302l.395.529.395-.537A2.377 2.377 0 0114.474 4a2.365 2.365 0 012.368 2.368c0 .277-.055.545-.142.79h1.721c.876 0 1.571.703 1.571 1.579L20 17.42c0 .876-.703 1.579-1.579 1.579H5.79a1.573 1.573 0 01-1.578-1.579V8.737c0-.876.702-1.58 1.578-1.58zm3.947-1.58a.792.792 0 00-.79.79c0 .435.356.79.79.79.434 0 .79-.355.79-.79a.792.792 0 00-.79-.79zm3.947.79c0-.434.356-.79.79-.79.434 0 .79.356.79.79 0 .435-.356.79-.79.79a.792.792 0 01-.79-.79zM5.79 17.421h12.63v-1.579H5.79v1.58zm12.631-3.947H5.79V8.736H9.8L8.158 10.97l1.279.924 1.879-2.558.79-1.074.789 1.074 1.879 2.558 1.279-.924-1.642-2.234h4.01v4.736z"
        fill={fill}
      />
    </IconBase>
  )
}
