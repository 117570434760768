import { GraphQLClient } from "graphql-request"
import localStorage from "@/utils/localStorage"
import apiUrl from "./apiUrl"
import authUserResetPasswordMutation from "@/graphql/authUserResetPassword"

const authUserResetPassword = async ({
  passwordConfirmation = "",
}) => {
  const authToken = localStorage.get("authToken")

  const authHeaders = {
    headers: {
      authorization: `JWT ${authToken}`,
    },
  }

  const graphQLClient = new GraphQLClient(apiUrl, authHeaders)

  const variables = {
    oldPassword: "SomePassword",
    newPassword: passwordConfirmation,
  }

  const data = await graphQLClient.request(
    authUserResetPasswordMutation,
    variables
  )

  return data
}

export default authUserResetPassword
