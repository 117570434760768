import React from "react"
import { Text, Span, Link } from "@/components"

export const ContactLine = ({ rest }) => (
  <Text level={2} {...rest}>
    <Span fontWeight="800">Have questions?</Span>{" "}
    <Link
      to="mailto:contact@learnwithsonora.com"
      as="a"
      color="neutralBlue"
      textDecoration="underline"
    >
      Contact us
    </Link>
    .
  </Text>
)
