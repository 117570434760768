import styled from "styled-components"
import {
  color,
  space,
  layout,
  border,
  background,
  position,
  typography,
  system,
} from "styled-system"

export const Slide = styled.div`
${typography}
${background}
${space}
${layout}
${position}
${color}
${border}
/* add custom props to styled-system */
${system({
  transform: true,
  transition: true,
})}

`
