import { GraphQLClient } from "graphql-request"

import apiUrl from "./apiUrl"
import createSubscriptionMutation from "@/graphql/createSubscription"
import localStorage from "@/utils/localStorage"

const createSubscription = async ({ priceId, iterationCount = 1 }) => {
  const authToken = localStorage.get("authToken")

  const authHeaders = {
    headers: {
      authorization: `JWT ${authToken}`,
    },
  }

  const graphQLClient = new GraphQLClient(apiUrl, authHeaders)

  const variables = {
    priceID: priceId,
    iterationCount,
    trialDays: 7,
  }

  const data = await graphQLClient.request(
    createSubscriptionMutation,
    variables
  )
  return data
}

export default createSubscription
