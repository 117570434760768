import React from "react"
import Img from "gatsby-image"
import {
  Heading,
  Text,
  Box,
  Link,
  ArrowGradient,
  VideoPlayer,
} from "@/components/index"

export const TestimonialCard = ({ name, image, children, videoUrl }) => {
  return (
    <Box>
      <VideoPlayer
        width={["100%", null]}
        mx={["auto", null, 0, null]}
        height="250px"
        url={videoUrl}
      />
      <Box display="flex" alignItems="center">
        <Heading level={3} mt="1.2rem">
          {name}
        </Heading>
      </Box>

      <Box mt="1">
        <Text level={2}>{children}</Text>
      </Box>
    </Box>
  )
}
