import React from "react"
import { IconBase } from "./IconBase"

export const Grow = ({
  width = 24,
  height = 24,
  fill = "#5127AE",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1877 9.156C15.9717 5.808 14.6157 2.544 12.0717 0C9.50372 2.568 8.08772 5.832 7.81172 9.156C9.34772 9.972 10.7637 11.028 11.9997 12.312C13.2357 11.04 14.6517 9.984 16.1877 9.156ZM12.0596 3.82793C12.8156 5.06393 13.3436 6.44393 13.6196 7.88393C13.0556 8.24393 12.5276 8.63993 12.0116 9.05993C11.5076 8.65193 10.9676 8.25593 10.4156 7.89593C10.7156 6.45593 11.2676 5.07593 12.0596 3.82793ZM8.328 12.3C9.768 13.332 11.016 14.64 12 16.14C14.58 12.204 18.984 9.6 24 9.6C24 15.984 19.968 21.384 14.364 23.388C13.596 23.652 12.816 23.856 12 24C11.184 23.868 10.392 23.664 9.636 23.388C4.032 21.384 0 15.984 0 9.6C3.108 9.6 5.976 10.596 8.316 12.288C8.25352 12.2463 8.18653 12.2047 8.11796 12.1621L8.11791 12.162C8.02844 12.1064 7.9363 12.0491 7.848 11.988C7.93855 12.0577 8.03314 12.1152 8.12708 12.1723C8.19498 12.2136 8.26255 12.2547 8.328 12.3ZM13.5837 21.12C13.0557 21.3 12.5277 21.444 11.9877 21.564C11.4597 21.456 10.9437 21.312 10.4517 21.132C6.50372 19.716 3.61172 16.344 2.71172 12.312C4.03172 12.624 5.29172 13.164 6.45572 13.908L6.43172 13.92L6.43174 13.92C6.58773 14.028 6.74372 14.136 6.89972 14.22L6.98372 14.268C8.17172 15.132 9.19172 16.2 9.99572 17.448L11.9997 20.52L14.0037 17.46C14.8317 16.2 15.8637 15.12 17.0397 14.268L17.1237 14.208C17.2317 14.148 17.3397 14.076 17.4476 14.0041L17.4477 14.004L17.4357 13.98C18.6117 13.2 19.9197 12.624 21.2877 12.3C20.3877 16.344 17.5077 19.716 13.5837 21.12ZM8.34051 12.2998C8.35337 12.312 8.37094 12.3272 8.38851 12.336C8.36451 12.324 8.35251 12.312 8.34051 12.3C8.34051 12.2999 8.34051 12.2998 8.34051 12.2998ZM8.32851 12.288C8.32851 12.288 8.34036 12.288 8.34051 12.2998C8.3358 12.2953 8.33173 12.2912 8.32851 12.288Z"
        fill={fill}
      />
    </IconBase>
  )
}
