import React from "react"
import { IconBase } from "./IconBase"

export const ArrowRight = ({
  width = 8,
  height = 12,
  fill = "#651FFF",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M.997 11.183L6.33 5.852.997.52"
        stroke={fill}
        strokeWidth={1.111}
      />
    </IconBase>
  )
}
