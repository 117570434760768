import styled from "styled-components"
import { heading1, body2, color, breakpoints } from "@/theme/index"

export const LegalText = styled.div`
  a {
    transition: all 300ms ease;
    color: ${color.neutralBlue};
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: ${color.tanzanite};
    }
  }

  strong {
    font-weight: 700;
  }
  h1 {
    ${heading1}
  }
  h2 {
    font-family: "Sohne";
    font-size: 2.4rem;
    line-height: 1.25;
    font-weight: 800;
  }
  h3 {
    ${body2}
  }
  p,
  li {
    ${body2}
  }

  ul {
    list-style: disc;
    padding: 0;
    margin: 0 0 0 2.4rem;

    li {
      padding: 0;
    }

    li + li {
      margin-top: 1.2rem;
      @media (min-width: ${breakpoints[0]}) {
        margin-top: 1.2rem;
      }
    }
  }

  p + p,
  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  h6 + p {
    margin-top: 1.2rem;
    @media (min-width: ${breakpoints[1]}) {
      margin-top: 1.2rem;
    }
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: 2.4rem;
    @media (min-width: ${breakpoints[1]}) {
      margin-top: 2.4rem;
    }
  }

  p + ul,
  p + div {
    margin-top: 2.4rem;
    @media (min-width: ${breakpoints[1]}) {
      margin-top: 2.4rem;
    }
  }

  ul + h1,
  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6 {
    margin-top: 2.4rem;
    @media (min-width: ${breakpoints[1]}) {
      margin-top: 2.4rem;
    }
  }

  ul + ul,
  ul + div,
  ul + p {
    margin-top: 2.4rem;
    @media (min-width: ${breakpoints[1]}) {
      margin-top: 2.4rem;
    }
  }
`
