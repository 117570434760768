import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

export const GlobalStyle = createGlobalStyle`

${normalize()}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  min-height: 100vh;
}

body, 
#___gatsby, 
#gatsby-focus-wrapper,
div[role="group"][tabindex] {
    height: inherit;
}


body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.tanzanite};
  position: relative;
  overflow-x: hidden;
}

div {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}


::selection {
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.white};
}

.active {
  font-weight: 800;
}

.active:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.tanzanite};
    transition: left .4s ease;

${
  "" /* .active:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #caa;
    background-image: linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%);
    transition: left .4s ease;
  } */
}

 
.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


input,
.StripeElement {
  font-family: "Sohne", sans-serif;
  font-weight: 400;
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  width: 100%;
  padding: 12px 14px 13px 14px;
  box-shadow: none;
  border: 1px solid #E1E0E3;
  ${"" /* border-radius: 6px; */}
  outline: 0;
  background: white;
}

input::placeholder {
  color: #5127AE;
}

.swiper-pagination-bullet {
  background: #5127AE;
  opacity: 0.2;
}

.swiper-pagination-bullet-active{
  background: #5127AE;
  opacity: 1;
}

`
