import React from "react"
import { IconBase } from "./IconBase"

export const Cal = ({ width = 25, height = 24, fill = "#B3A8CB", ...rest }) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.944 19.778c.856 0 1.556-.7 1.556-1.556V7.333c0-.855-.7-1.555-1.556-1.555h-.777V4.222H15.61v1.556H9.39V4.222H7.833v1.556h-.777c-.864 0-1.548.7-1.548 1.555L5.5 18.223c0 .855.692 1.555 1.556 1.555h10.888zm-7.777-7v-1.556H8.61v1.556h1.556zm-3.111-3.89h10.888V7.334H7.056V8.89zm10.888 1.556v7.778H7.056v-7.778h10.888zm-3.11 2.334h1.555v-1.556h-1.556v1.556zm-1.556 0h-1.556v-1.556h1.556v1.556z"
        fill={fill}
      />
    </IconBase>
  )
}

export const CalComplete = ({ width = 37, height = 36, ...rest }) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6501 9.90025L30.6501 28.8003C30.6501 30.2853 29.4351 31.5003 27.9501 31.5003L9.0501 31.5003C7.5516 31.5003 6.3501 30.2853 6.3501 28.8003L6.3636 9.90025C6.3636 8.41525 7.5516 7.20025 9.0501 7.20025L10.4001 7.20025L10.4001 4.50024L13.1001 4.50024L13.1001 7.20025L23.9001 7.20025L23.9001 4.50024L26.6001 4.50024L26.6001 7.20025L27.9501 7.20025C29.4351 7.20025 30.6501 8.41525 30.6501 9.90025ZM9.05033 12.6002L27.9504 12.6002L27.9504 9.9002L9.05033 9.9002L9.05033 12.6002ZM27.9503 15.3002L27.9503 28.8002L9.05033 28.8002L9.05033 15.3002L27.9503 15.3002Z"
        fill="#5127AE"
      />
      <path
        d="M14.0316 21.7503L16.8752 25.0002L23.7813 17.6877"
        stroke="#0BC3B8"
        strokeWidth="2"
      />
    </IconBase>
  )
}

export const CalIncomplete = ({ width = 37, height = 36, ...rest }) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6501 9.90025L30.6501 28.8003C30.6501 30.2853 29.4351 31.5003 27.9501 31.5003L9.0501 31.5003C7.5516 31.5003 6.3501 30.2853 6.3501 28.8003L6.3636 9.90025C6.3636 8.41525 7.5516 7.20025 9.0501 7.20025L10.4001 7.20025L10.4001 4.50024L13.1001 4.50024L13.1001 7.20025L23.9001 7.20025L23.9001 4.50024L26.6001 4.50024L26.6001 7.20025L27.9501 7.20025C29.4351 7.20025 30.6501 8.41525 30.6501 9.90025ZM9.05033 12.6002L27.9504 12.6002L27.9504 9.9002L9.05033 9.9002L9.05033 12.6002ZM27.9503 15.3002L27.9503 28.8002L9.05033 28.8002L9.05033 15.3002L27.9503 15.3002Z"
        fill="#B3A8CB"
      />
      <path
        d="M23.75 25.9427L22.6925 27.0002L18.5 22.8077L14.3075 27.0002L13.25 25.9427L17.4425 21.7502L13.25 17.5577L14.3075 16.5002L18.5 20.6927L22.6925 16.5002L23.75 17.5577L19.5575 21.7502L23.75 25.9427Z"
        fill="#ED5B4C"
      />
    </IconBase>
  )
}
