import { color } from "@/theme/"
import { useMemo } from "react"

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "18px",
          color: color.tanzanite,
          border: "1px solid red",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: color.purpley,
          },
        },
        invalid: {
          color: color.alertRed,
        },
      },
    }),
    []
  )

  return options
}

export default useOptions
