import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Box,
  Wordmark,
  CTA,
  Link,
  Hamburger,
  MobileWrap,
  Text,
} from "@/components/"
import webAppUrl from "@/api/webAppUrl"

export const Header = ({ headerTheme = "light", headerType = "default", squeeze = false}) => {
  const [open, setOpen] = useState(false)
  const dark = headerTheme === "light" ? false : true
  const linkColor = dark ? "white" : "#5127AE"
  return (
    <Box
      width="100%"
      height={open ? "100vh" : "100%"}
      color={dark ? "white" : "#5127AE"}
      bg={dark ? (open ? "#5127AE" : "none") : "rgba(255,255,255, 0.96)"}
      css={{ backdropFilter: "5px" }}
      boxShadow={headerType === "simple" ? "0px 1px 1px #E1E0E3" : "0px"}
    >
      <MobileWrap
        height="100%"
        display="flex"
        flexDirection={open ? "column" : "row"}
        mx="auto"
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent={headerType === "checkout" ? "center" : "flex-start"}
          height="9.6rem"
          borderBottom={[open ? "1px solid" : "0px", null, null, "0px"]}
          borderColor={open ? "purpley" : "none"}
        >
          {headerType !== "checkout" && (
            <Box display={["block", null, null, "none"]}>
              <Hamburger
                open={open}
                onClick={() => setOpen(!open)}
                dark={dark}
              />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection={["column", null, "row"]}
            justifyContent="center"
            alignItems="center"
            mx={["auto", null, null, "0"]}
          >
            {squeeze ? (
              <Wordmark />
            ) : ( 
              <Link to="/">
                <Wordmark />
              </Link>
            )}
            {" "}
            <Box
              display={["none", null, "inline-block"]}
              color="purpley"
              pr="2rem"
              pl="2.4rem"
            >
              | 
            </Box>
            <Box>
              <Brand>GUITAR INTENSIVE</Brand>
            </Box>
          </Box>
        </Box>

        {headerType !== "checkout" && (
          <Box
            ml={["0", null, null, "auto"]}
            display={[open ? "flex" : "none", null, null, "inline-flex"]}
            flexDirection={["column", null, null, "row"]}
            alignItems="center"
            justifyContent={["flex-start", null, null, "flex-end"]}
            width="100%"
          >
            <Box
              order={["2", null, null, "1"]}
              display="flex"
              flexDirection={["column", null, null, "row"]}
              alignItems="center"
            >
              <Link
                variant="nav"
                to="/"
                mr={["0", null, null, "2.4rem"]}
                mb={["2.4rem", null, null, "0"]}
                color={linkColor}
                activeClassName="active"
                partiallyActive={false}
                display={("inline-block", null, null, "none")}
              >
                Home
              </Link>
              {/* <Link
                variant="nav"
                to="/about"
                mr={["0", null, null, "2.4rem"]}
                mb={["2.4rem", null, null, "0"]}
                color={linkColor}
                activeClassName="active"
                partiallyActive={true}
              >
                About
              </Link> */}
              <Link
                variant="nav"
                to="https://new.learnwithsonora.com/testimonials-page"
                mr={["0", null, null, "2.4rem"]}
                mb={["2.4rem", null, null, "0"]}
                color={linkColor}
                activeClassName="active"
                partiallyActive={true}
              >
                Testimonials
              </Link>
              <Link
                variant="nav"
                to="https://new.learnwithsonora.com/mentors"
                mr={["0", null, null, "2.4rem"]}
                mb={["2.4rem", null, null, "0"]}
                color={linkColor}
                activeClassName="active"
                partiallyActive={true}
              >
                Mentorship
              </Link>
              <Link
                variant="nav"
                to="/freetraining"
                mr={["0", null, null, "2.4rem"]}
                mb={["2.4rem", null, null, "0"]}
                color={linkColor}
                activeClassName="active"
                partiallyActive={true}
              >
                Free Training
              </Link>
              {!squeeze &&
                <Link
                  variant="nav"
                  to={webAppUrl}
                  mr={["0", null, null, "2.4rem"]}
                  mb={["2.4rem", null, null, "0"]}
                  color={linkColor}
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Sign In
                </Link>
              }
            </Box>
            <Box
              order={["1", null, null, "2"]}
              mb={["7.2rem", null, null, "0"]}
              textAlign="center"
              mt={["2.4rem", null, null, "0"]}
            >
              <CTA to="/application" variant="special" mb="1.2rem">
                APPLY
              </CTA>
              {/* <Text
                fontStyle="italic"
                variant="small"
                display={["block", null, null, "none"]}
              >
                No obligation, cancel anytime
              </Text> */}
            </Box>
          </Box>
        )}

        {(headerType === "checkout" && !squeeze) && (
          <Box
            ml="auto"
            width="10rem"
            display={["none", null, "flex"]}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Link to={webAppUrl} color={linkColor} variant="nav2">
              SIGN IN
            </Link>
          </Box>
        )}
      </MobileWrap>
      {headerType === "checkout" && (
        <Box
          width="100%"
          height="4px"
          backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
        />
      )}
    </Box>
  )
}

const Brand = styled.h3`
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`
Header.propTypes = {
  headerType: PropTypes.oneOf(["default", "checkout", "simple"]),
  headerTheme: PropTypes.oneOf(["dark", "light"]),
  squeeze: PropTypes.bool
}
