import React from "react"
import styled from "styled-components"

export const Hamburger = ({ dark = true, ...props }) => {
  const { open, setOpen } = props

  return (
    <Toggle dark={dark} onClick={() => setOpen(!open)} {...props}>
      <FirstLine {...props} />
      <SecondLine {...props} />
      <ThirdLine {...props} />
    </Toggle>
  )
}

Hamburger.defaultProps = {
  width: 22,
  height: 12,
  open: false,
  strokeWidth: 2,
  borderRadius: 0,
}

const Toggle = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
  transform: rotate(0deg);
  span {
    background: ${({ dark }) => (dark ? "white" : "#5127AE")};
  }
`

const Line = styled.span`
  display: inline-block;
  width: 100%;
  width: 20px;
  position: absolute;

  height: ${props => props.strokeWidth}px;
  border-radius: ${props => props.borderRadius}px;
  transform-origin: center;
  transition-duration: 0.4s;
  transition-timing-function: ease;
`

const FirstLine = styled(Line)`
  margin-top: -${props => props.strokeWidth}px;
  transform: ${props =>
    props.open
      ? `translate3d(0px, ${props.height / 2}px, 0px) rotate(45deg)`
      : `translate3d(0px, 0px, 0px) rotate(0deg)`};
`

const SecondLine = styled(Line)`
  transition-timing-function: ease-out;
  transition-duration: 0.4s;
  opacity: ${props => (props.open ? "0" : "1")};
  top: ${props => props.height / 2}px;
  margin-top: -${props => props.strokeWidth}px;
`

const ThirdLine = styled(Line)`
  margin-top: -${props => props.strokeWidth}px;
  transform: ${props =>
    props.open
      ? `translate3d(0px, ${props.height / 2}px, 0px) rotate(-45deg)`
      : `translate3d(0px, ${props.height}px, 0px) rotate(0deg)`};
`
