import styled, { css } from "styled-components"
import { Field } from "formik"
import { inputField, labelField } from "@/theme/index"

/* input field */
export const Input = styled(Field)`
  ${inputField}
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.tanzanite};
  margin-bottom: 0.8rem;
  width: 100%;
  min-width: 10rem;
  padding: 1.6rem 2rem;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.tanzanite};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.amethyst};
  }

  &:focus,
  &:active {
    box-shadow: 0px;
    outline: none;
    padding: 1.6rem 2rem;
    opacity: 1;
    color: ${({ theme }) => theme.colors.tanzanite};
    border: 2px solid ${({ theme }) => theme.colors.tourmaline};
    background: ${({ theme }) => theme.colors.opal};
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: none;
    border: 2px solid ${({ theme }) => theme.colors.tanzanite};
    box-shadow: 0px;
    -webkit-box-shadow: 0px;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.tanzanite};
  }

  ${({ isvalid }) =>
    isvalid &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.validGreen};
      padding: 1.6rem 2rem;

      &:focus,
      &:active {
        box-shadow: 0px;
        outline: none;
        padding: 1.6rem 2rem;
        background: ${({ theme }) => theme.colors.opal};
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 2px solid ${({ theme }) => theme.colors.validGreen};
        box-shadow: 0px;
        outline: none;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.alertRed};
      padding: 1.6rem 2rem;

      &:focus,
      &:active {
        box-shadow: 0px;
        border: 2px solid ${({ theme }) => theme.colors.alertRed};
        padding: 1.6rem 2rem;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0px;
        border: 2px solid ${({ theme }) => theme.colors.alertRed};
        padding: 1.6rem 2rem;
        outline: none;
      }
    `}
`

export const Label = styled.label`
  ${labelField}
  margin-top: 1.5rem;
  width: 100%;
  display: block;
  position: relative;
  span {
    font-size: 14px;
    position: absolute;
    top: -0.8rem;
    color: ${({ theme }) => theme.colors.tanzanite};
    background: white;
    padding: 0 0.3rem;
    left: 1.8rem;
  }
`

export const Submit = styled.button`
  width: 100%;
  margin-top: 1.5rem;
  background-color: rgb(24, 81, 187);
  display: block;
  text-align: center;
  font-size: 2rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 700;
  height: 3rem;
  white-space: nowrap;
  color: rgb(232, 243, 255) !important;
  padding: 0.5rem 1rem;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: pointer;
    background-color: rgb(163, 168, 173);
    box-shadow: none;
    color: rgb(255, 255, 255) !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`

export const Select = styled.select`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.tanzanite};
  margin-bottom: 0.8rem;
  width: 100%;
  min-width: 10rem;
  padding: 1.6rem 2rem;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.tanzanite};
  display: block;
  border-radius: 0; // square edges
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.tanzanite};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`
export const InlineError = styled.div`
  ${labelField}
  color: red !important;
  display: block;

  padding: 0.5rem 0;
  margin-top: 1rem;
  font-size: 14px;
  white-space: pre-line;
`

export const Checkbox = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: 1.2rem 0;

  input {
    flex: 0 0 3.3rem;
    height: 3.3rem;
    appearance: none;
    border: 2px solid ${({ theme }) => theme.colors.tanzanite};
    border-radius: none;
    outline: none;
    transition-duration: 0.3s;
    background-color: none;
    cursor: pointer;
  }

  input:checked {
    ${"" /* border: 1px solid #41b883; */}
  }

  input:checked + span::before {
    font-size: 1.5em;
    font-weight: bold;
    content: "✓";
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.colors.tanzanite};
    position: absolute;
    left: 0.7rem;
    top: 0.3rem;
  }

  input:active {
    border: 2px solid ${({ theme }) => theme.colors.tanzanite};
  }

  span {
    ${labelField}
    margin-left: 1.8rem;
    user-select: none;
  }
`

export const ErrorMessage = styled.span`
  ${labelField}
  color: pink !important;
  user-select: none;
`

export const StripeForm = styled.form`
  border: none;
`
