import { GraphQLClient } from "graphql-request"

import apiUrl from "./apiUrl"
import createUserMutation from "@/graphql/createUser"

const graphQLClient = new GraphQLClient(apiUrl)

const createUser = async ({
  first_name = "",
  last_name = "",
  email = "",
  phone_number = "",
}) => {
  const variables = {
    email,
    first_name,
    last_name,
    phone_number,
    password: "SomePassword",
  }

  const data = await graphQLClient.request(createUserMutation, variables)
  return data
}

export default createUser
