import styled, { css } from "styled-components"
import {
  color,
  space,
  layout,
  border,
  flexbox,
  position,
  typography,
} from "styled-system"
import {
  listItem,
  body,
  breakpoints,
  listBullet,
  checkBullet,
} from "@/theme/index"

export const List = styled.ul`
  ${space}
  ${layout}
  ${position}
  ${color}
  ${flexbox}
    ${typography}
  ${border}

  ${({ bullets }) =>
    bullets === "none" &&
    css`
      list-style: none;
      padding: 0;
      margin: 0;
      ${listItem}
    `}

    ${({ bullets }) =>
      bullets === "default" &&
      css`
        ${({ level }) => (level === 1 || level === "1") && listItem}
        ${({ level }) =>
          (level === 2 || level === "2") && body}
        list-style: none;
        list-style-position: outside;
        padding: 0;
        margin: 0;

        li::before {
          ${listBullet}
        }

        li {
          padding: 0;
        }

        li + li {
          margin-top: 1rem;
          @media (min-width: ${breakpoints[0]}) {
            margin-top: 1rem;
          }
        }
      `}

      ${({ bullets }) =>
        bullets === "checkmark" &&
        css`
          ${({ level }) => (level === 1 || level === "1") && listItem}
          list-style: none;
          list-style-position: outside;
          padding: 0;
          margin: 0;

          li::before {
            ${checkBullet}
          }

          li {
            display: flex;
            align-items: flex-start;
            padding: 0;
          }

          li + li {
            margin-top: 1rem;
            @media (min-width: ${breakpoints[0]}) {
              margin-top: 1rem;
            }
          }
        `}
`

List.defaultProps = {
  bullets: "default",
  level: 1,
}

export const ListItem = styled.li`
  ${space}
  ${layout}
  ${position}
  ${color}
  ${flexbox}
    ${typography}
  ${border}
`
