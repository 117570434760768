import React from "react"
import { IconBase } from "./IconBase"

export const LessonMap = ({
  width = 36,
  height = 36,
  fill = "#5127AE",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.73 7.535l.187-.035c.326 0 .583.257.583.583v17.64a.588.588 0 01-.42.56L21.5 28.5l-7-2.45-6.23 2.415-.187.035a.578.578 0 01-.583-.583v-17.64c0-.269.175-.479.42-.56L14.5 7.5l7 2.45 6.23-2.415zm-7.396 4.48l-4.667-1.633v13.603l4.667 1.633V12.015zm-10.501-.478l3.5-1.179v13.65l-3.5 1.354V11.537zm12.834 14.105l3.5-1.179V10.65l-3.5 1.353v13.639z"
        fill="#5127AE"
      />
    </IconBase>
  )
}
