import { GraphQLClient } from "graphql-request"
import apiUrl from "./apiUrl"
import tokenAuthMutation from "@/graphql/tokenAuth"
import localStorage from "@/utils/localStorage"

const graphQLClient = new GraphQLClient(apiUrl)

const tokenAuth = async ({ email, password = "SomePassword" }) => {
  const variables = {
    email,
    password,
  }

  const data = await graphQLClient.request(tokenAuthMutation, variables)
  const authToken = data.tokenAuth.token
  localStorage.set("authToken", authToken)
  return data
}

export default tokenAuth
