import React from "react"
import {
    Heading,
    Text,
    CTA,
    Box,
    Width,
    VideoPlayer,
} from "@/components/index"
import { color } from "@/theme/index"

export const MentorHeader = ({ name, videoUrl }) => {
    return (
        <Box
            color="white"
            position="relative"
            zIndex="3"
            top="0"
            width="100%"
            display="flex"
            flexDirection="column"
            textAlign="center"
            backgroundImage="linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)"
            pt={["12.5rem", null, null, "10rem"]}
            pb={["4.8rem", null, null, "15rem"]}
            variant="slopeBottomRight"
        >
            <Width
                display="flex"
                flexDirection={["column", null, "row", null]}
                textAlign="left"
                alignItems="center"
                flex="1"
                pt={["0", null, null, "10rem"]}
                pb="3.6rem"
            >
                <Box
                    width={["100%", "80%", 5 / 12, null]}
                    mr={["auto", null, "3.6rem", null]}
                    ml={["auto", null, 0, null]}
                    pr={["auto", null, "3rem", null]}
                    order={[2, null, 1, null]}
                    textAlign={["center", null, "left", null]}
                >
                    <Heading
                        mb="1.8rem"
                        mx={["auto", 0, null, null]}
                        color={color.thulite}
                    >
                        Study guitar with <br />{name}
                    </Heading>
                    <Text>Enroll in <strong>Sonora Guitar Intensive</strong> and study 1-on-1 with {name} for 3 months. <br/><br/>Receive lessons, daily feedback on your playing, and a clear path to fretboard fluency.</Text>
                    <CTA variant="special" to="/application" mb="1.2rem" mt="5rem">
                        Apply Now
                    </CTA>
                    <Text variant="small">Limited Availability.</Text>
                </Box>

                <VideoPlayer
                    width={["100%", "80%", 7 / 12, null]}
                    mx={["auto", null, 0, null]}
                    mb={["3rem", null, null, "0"]}
                    order={[1, null, 2, null]}
                    url={videoUrl}

                />
            </Width>
        </Box>
    )
}
