import React from "react"
import { Heading, Text, CTA, Box } from "@/components/index"

export const SidebarBanner = () => {
  return (
    <Box textAlign="center">
      <Heading level="2" mb="1rem" color="amethyst">
        Interested in the Fellowship Program?
      </Heading>
      <Text level="2" mb={["3.6rem", null, "1.8rem", null]}>
        Get 1-on-1 access the world’s top guitarists and educators, weekly
        Masterclasses, facilitated practice sessions, and more.
      </Text>
      <CTA to="/application" variant="primary" mx="3.6rem">
        Apply Now
      </CTA>
    </Box>
  )
}
