import styled from "styled-components"
import { breakpoints } from "@/theme/index"
import { Box } from "@/components/index"

export const Width = styled(Box)`
  width: 100%;
  max-width: calc(100% - 4.8rem);
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${breakpoints[0]}) {
    max-width: calc(100% - 12rem);
  }

  @media (min-width: ${breakpoints[2]}) {
    max-width: 102rem;
  }
`

export const MobileWrap = styled(Box)`
  width: 100%;
  max-width: calc(100% - 1rem);
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${breakpoints[0]}) {
    max-width: calc(100% - 12rem);
  }

  @media (min-width: ${breakpoints[1]}) {
    max-width: auto;
  }
`
