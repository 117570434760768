import React from "react"
import styled, { css } from "styled-components"
import {
  color,
  space,
  layout,
  border,
  typography,
  position,
} from "styled-system"
import { nav, footernav } from "@/theme/index"

import { Link as GatsbyLink } from "gatsby"

export const Link = ({ to, as, hover, children, ...rest }) =>
  as === "a" ? (
    <StyledAnchor
      href={to}
      hover={hover}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </StyledAnchor>
  ) : (
    <StyledLink to={to} hover={hover} {...rest}>
      {children}
    </StyledLink>
  )

const base = css`
    /* add variants */
    ${({ variant }) =>
      variant === "nav" &&
      css`
        ${nav}
        position: relative;
        text-decoration: none;
      `}

    ${({ variant }) =>
      variant === "mentor" &&
      css`
        text-decoration: none;
      `}

          /* add variants */
    ${({ variant }) =>
      variant === "nav2" &&
      css`
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        text-decoration: none;
      `}

      ${({ variant }) =>
        variant === "checkout" &&
        css`
          font-weight: bold;
          font-size: 1.4rem;
          letter-spacing: 0.08em;
          text-decoration: underline !important;
          color: ${({ theme }) => theme.colors.neutralBlue};
        `}

        ${({ variant }) =>
          variant === "smallUppercase" &&
          css`
            font-size: 1.4rem;
            line-height: 1.2;
            letter-spacing: 0.08em;
            font-weight: 800;
            text-decoration: none;
          `}

      ${({ variant }) =>
        variant === "footer" &&
        css`
          ${footernav}
          position: relative;
          text-decoration: underline;
        `}

        ${({ underline }) =>
          underline &&
          css`
            text-decoration: underline;
          `}

    ${typography}
    ${space}
    ${layout}
    ${position}
    ${color}
    ${border}    

    `

// extend Link
export const StyledLink = styled(GatsbyLink)`
  ${base}

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        color: ${hover};
      }
    `}
`

// extend Anchor
export const StyledAnchor = styled.a`
  ${base}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        color: ${hover};
      }
    `}
`
