import React from "react"
import { IconBase } from "./IconBase"

export const Facebook = ({
  width = 18,
  height = 18,
  shouldScale = true,
  fill = "#5127AE",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      shouldScale={shouldScale}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M18 1.929V16.07A1.929 1.929 0 0116.071 18h-3.427v-7.12h2.435l.35-2.716h-2.785V6.43c0-.788.217-1.322 1.346-1.322h1.439V2.68a19.34 19.34 0 00-2.098-.109c-2.073 0-3.495 1.266-3.495 3.592v2.005H7.393v2.716H9.84V18H1.929A1.929 1.929 0 010 16.071V1.93A1.93 1.93 0 011.929 0H16.07C17.136 0 18 .864 18 1.929z"
        fill={fill}
      />
    </IconBase>
  )
}
