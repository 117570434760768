import { gql } from "graphql-request"

const mutation = gql`
  mutation(
    $priceID: String!,
    $iterationCount: Int!,
    $trialDays: Int!
  ) {
    createStripeSubscriptionSchedule(
      priceId: $priceID,
      iterationCount: $iterationCount,
      trialDays: $trialDays
    ) { ok }
  }
`

export default mutation
