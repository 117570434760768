import React from "react"
import { IconBase } from "./IconBase"

export const SmallWave = ({ width = 60, height = 10, ...rest }) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 60 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 7.413h4.445c2.222 0 2.222-3.288 4.445-3.288 2.222 0 2.222 3.685 4.445 3.685 2.222 0 2.219-5.223 4.441-5.223C20 2.587 20 9 23.331 9s3.46-8 6.44-8c3.13 0 3.563 8 6.895 8 3.332 0 3.332-6.413 5.554-6.413 2.223 0 2.223 5.223 4.445 5.223 2.223 0 2.223-3.692 4.445-3.692 2.223 0 2.223 3.295 4.445 3.295H60"
        stroke="url(#prefix__paint0_linear)"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={0}
          y1={9}
          x2={11.908}
          y2={-19.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#651FFF" />
          <stop offset={1} stopColor="#D04556" />
        </linearGradient>
      </defs>
    </IconBase>
  )
}
