import React from "react"
import { IconBase } from "./IconBase"

export const Mastery = ({
  width = 37,
  height = 36,
  fill = "#5127AE",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.625 24.389v-7.224l-3-1.235v4.32h-2.25v-6c0-.456.275-.867.697-1.04l12.75-5.25c.274-.113.582-.113.856 0l12.75 5.25a1.125 1.125 0 010 2.08l-4.553 1.875v7.224c0 .515-.35.964-.849 1.09-2.86.726-5.189 1.132-7.603 1.146-2.414.013-4.855-.366-7.948-1.145-.5-.126-.85-.576-.85-1.091zm8.197-3.849l-5.947-2.448v5.414c2.573.607 4.589.88 6.536.869 1.931-.011 3.842-.301 6.214-.867v-5.416l-5.947 2.448a1.126 1.126 0 01-.856 0zM6.5 24a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm12.75-5.717L9.455 14.25l9.795-4.033 9.795 4.033-9.795 4.033z"
        fill="#5127AE"
      />
    </IconBase>
  )
}
