import styled, { css } from "styled-components"
import {
  color,
  space,
  layout,
  border,
  grid,
  flexbox,
  position,
  typography,
  system,
  background,
  shadow,
  variant,
} from "styled-system"

// default box for layout
export const Box = styled.div`
  ${space}
  ${layout}
  ${position}
  ${color}
  ${flexbox}
  ${typography}
  ${border}
  ${grid}
  ${shadow}
  ${background}
  /* add custom props to styled-system */
  ${system({
    transform: true,
    transition: true,
    mixBlendMode: true,
  })}
  /* Variants styles */
  ${variant({
    variants: {
      slopeTopRight: {
        paddingTop: "7.2rem",
        clipPath: `polygon(0 3.6rem, 100% 0, 100% 100%, 0% 100%)`,
      },
      slopeTopLeft: {
        paddingTop: "7.2rem",
        clipPath: `polygon(0 0, 100% 3.6rem, 100% 100%, 0% 100%)`,
      },
      slopeTopAndBottom: {
        paddingTop: "7.2rem",
        paddingBottom: "7.2rem",
        clipPath: `polygon(0 3.6rem, 100% 0, 100% calc(100% - 3.6rem), 0% 100%)`,
      },
      slopeTopAndBottomAlt: {
        paddingTop: "7.2rem",
        paddingBottom: "7.2rem",
        clipPath: `polygon(0 3.6rem, 100% 0, 100% 100%, 0% calc(100% - 3.6rem))`,
      },
      slopeBottomRight: {
        paddingBottom: "7.2rem",
        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 3.6rem))`,
      },
      slopeBottomLeft: {
        paddingBottom: "7.2rem",
        clipPath: `polygon(0 0, 100% 0, 100% calc(100% - 3.6rem), 0 100%)`,
      },
    },
  })}

  /* enable border when passing prop debug */
  ${({ debug }) =>
    debug &&
    css`
      border: 2px solid ${debug};
    `}
`
