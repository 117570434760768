const set = (key, value) => {
  if (typeof window !== `undefined`) {
    return window.localStorage.setItem(key, JSON.stringify(value))
  }

  return null
}

const get = key => {
  if (typeof window !== `undefined`) {
    const output = JSON.parse(window.localStorage.getItem(key))
    return output
  }

  return null
}

export default {
  set,
  get,
}
