import React from "react"
import Img from "gatsby-image"
import { Heading, Text, Box, Link, ArrowGradient } from "@/components/index"
import { color } from "@/theme/index"

export const CaseStudyCard = ({ name, slug, image, children, subtitle }) => {
  return (
    <Box>
      <Img alt={name} fluid={image} />
      {slug.length ? (
        <Link to={`/case-studies/${slug}`} variant="mentor">
          <Box display="flex" alignItems="center">
            <Heading level={3} my="1.2rem">
              {name}
            </Heading>
            <Heading level={3} my="1.2rem">
              {subtitle}
            </Heading>
            <ArrowGradient mt="-.2rem" ml=".2rem" height="2.4rem" />
          </Box>
        </Link>
      ) : (
        <Box display="flex" alignItems="center">
          <Heading level={3} mt="1.2rem">
            {name}
          </Heading>
        </Box>
      )}
    <Text fontSize="14px"
                  fontWeight="bold"
                  color={color.tanzanite}
                  mb=".6rem">{subtitle}</Text>

      <Box mt="1">
      <Text level={2}>{children}</Text>
      </Box>
      
    </Box>
  )
}
