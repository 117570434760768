import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { navigate } from "gatsby"
import styled from "styled-components"
import webAppUrl from "@/api/webAppUrl"
import authUserResetPassword from "@/api/authUserResetPassword"
import useStore from "@/store/index"

import { Box, Button, Text, Label, Input, Span } from "@/components/index"

export const CreatePassword = () => {
  const user = useStore(state => state.user)
  const [isError, setIsError] = useState(false)
  const [log, setLog] = useState(null)
  const [showPass, togglePass] = useState(false)

  // validate fields and provide errors.
  let validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(10, "Your password must be longer than 10 characters."),
    passwordConfirmation: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value
      }
    ),
  })

  const path = user.email ? `/?email=${user.email}/` : `/`

  const onSubmit = async (values, actions) => {
    try {
      await authUserResetPassword(values)
      navigate(`${webAppUrl}${path}`)
      actions.setSubmitting(false)
    } catch (error) {
      actions.setSubmitting(false)
      setIsError(true)
      setLog(error.response)
    }
  }

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleSubmit, isSubmitting }) => (
        <Form name="create-password">
          <Box
            boxShadow="0px 0px 28px rgba(27, 6, 70, 0.2)"
            borderRadius="0.4rem"
            p="3.6rem"
            bg="white"
          >
            <Box textAlign="left" display="flex" justifyContent="space-between">
              <Title>Create password</Title>
              <ShowField type="button" onClick={(e) => {
                  e.preventDefault()
                  togglePass(!showPass)
                }}>
                {!showPass && (
                  <Span mr="0.5rem">
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.5 6.1362C1.91544 2.54441 5.40906 -0.00012207 9.49994 -0.00012207C13.5908 -0.00012207 17.0844 2.54441 18.4999 6.1362C17.0844 9.72799 13.5908 12.2725 9.49994 12.2725C5.40906 12.2725 1.91544 9.72799 0.5 6.1362ZM16.7158 6.13623C15.3658 3.37897 12.6004 1.63626 9.49951 1.63626C6.39862 1.63626 3.63319 3.37897 2.2832 6.13623C3.63319 8.89348 6.39862 10.6362 9.49951 10.6362C12.6004 10.6362 15.3658 8.89348 16.7158 6.13623ZM9.50016 4.09083C10.6292 4.09083 11.5456 5.00719 11.5456 6.13627C11.5456 7.26535 10.6292 8.18171 9.50016 8.18171C8.37108 8.18171 7.45472 7.26535 7.45472 6.13627C7.45472 5.00719 8.37108 4.09083 9.50016 4.09083ZM5.81792 6.13624C5.81792 4.10716 7.47063 2.45445 9.49971 2.45445C11.5288 2.45445 13.1815 4.10716 13.1815 6.13624C13.1815 8.16532 11.5288 9.81803 9.49971 9.81803C7.47063 9.81803 5.81792 8.16532 5.81792 6.13624Z"
                        fill="#5127AE"
                      />
                    </svg>
                  </Span>
                )}
                {showPass ? "Hide" : "Show"}
              </ShowField>
            </Box>

            {/* password */}
            <Label htmlFor="password">
              {touched.password && !errors.password && (
                <span>New Password*</span>
              )}
              <Input
                type={showPass ? "text" : "password"}
                name="password"
                id="password"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="password"
                placeholder={"New password"}
                valid={touched.password && !errors.password}
                error={touched.password && errors.password}
              />
              {touched.password && errors.password && (
                <>
                  <Text
                    variant="small"
                    color="alertRed"
                    mb="1.8rem"
                    textAlign="left"
                  >
                    {errors.password}
                  </Text>
                </>
              )}
            </Label>

            {/* passwordConfirmation */}
            <Label htmlFor="passwordConfirmation">
              {touched.passwordConfirmation && !errors.passwordConfirmation && (
                <span>Confirm Password*</span>
              )}

              <Input
                type={showPass ? "text" : "password"}
                name="passwordConfirmation"
                id="passwordConfirmation"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="passwordConfirmation"
                placeholder={"Confirm new password"}
                valid={
                  touched.passwordConfirmation && !errors.passwordConfirmation
                }
                error={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
              />
              {touched.passwordConfirmation && errors.passwordConfirmation && (
                <>
                  <Text
                    variant="small"
                    color="alertRed"
                    mb="1.8rem"
                    textAlign="left"
                  >
                    {errors.passwordConfirmation}
                  </Text>
                </>
              )}
            </Label>
          </Box>

          {isError && (
            <Text variant="small" color="alertRed" mb="1.8rem">
              {log && log.data ? "Ouch." : "Something went wrong"}
            </Text>
          )}

          <Box mt="3.6rem" textAlign="center">
            <Button
              variant="special"
              type="submit"
              disabled={
                isSubmitting ||
                !touched.password ||
                !touched.passwordConfirmation ||
                (touched.password && errors.password) ||
                (touched.passwordConfirmation && errors.passwordConfirmation)
              }
            >
              {isSubmitting ? `Submitting...` : "Save & Continue"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const ShowField = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: all 350ms ease;
  white-space: nowrap;
  display: inline-block;
  background: transparent;
  color: inherit;
  font: inherit;

  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.18;
  letter-spacing: 0.08em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tanzanite};
  border: none;
`

const Title = styled.h2`
  font-family: "Sohne";
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 1.2;
`
