import styled, { css } from "styled-components"
import { color, space, layout, typography, system } from "styled-system"
import { heading1, heading2, headingXL } from "@/theme/index"

export const Heading = styled.h2`
  ${typography}
  ${space}
  ${color}
  ${layout}
  ${system({
    fontStyle: true,
  })}
  
  ${({ level }) => (level === 1 || level === "1") && heading1}
  ${({ level }) => (level === 2 || level === "2") && heading2}
  ${({ level }) => (level === "xl") && headingXL}

  ${({ hover }) =>
    hover &&
    css`
      transition: color 350ms ease;
      &:hover {
        color: ${hover};
        transition: color 350ms ease;
      }
    `}

`

Heading.defaultProps = {
  level: 1,
}
