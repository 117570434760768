import { gql } from "graphql-request"

const mutation = gql`
  mutation(
    $newPassword: String!,
    $oldPassword: String!
  ) {
    authUserResetPassword(
        newPassword: $newPassword,
        oldPassword: $oldPassword
    ) { ok }
  }
`

export default mutation
