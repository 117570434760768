import React from "react"
import { IconBase } from "./IconBase"

export const ArrowGradient = ({
  width = 34,
  height = 56,
  fill = "none",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 34 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10 46l14-18-14-18"
        stroke="url(#prefix__paint0_linear)"
        strokeWidth={3}
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={10}
          y1={46}
          x2={28.053}
          y2={43.786}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#651FFF" />
          <stop offset={1} stopColor="#D04556" />
        </linearGradient>
      </defs>
    </IconBase>
  )
}

export const ArrowAlt = ({
  width = 16,
  height = 56,
  fill = "none",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 16 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 54.5817L14 27.791L2 1.00024"
        stroke="#B3A8CB"
        strokeWidth="3"
      />
    </IconBase>
  )
}
