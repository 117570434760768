import React from "react"
import { IconBase } from "./IconBase"

export const Relationships = ({
  width = 28,
  height = 20,
  fill = "#5127AE",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95 10C12.5555 10 14.675 7.88055 14.675 5.27505C14.675 2.66955 12.5555 0.55005 9.95 0.55005C7.3445 0.550049 5.225 2.66955 5.225 5.27505C5.225 7.88055 7.3445 10 9.95 10ZM0.499999 17.0875C0.499999 13.942 6.791 12.3625 9.95 12.3625C13.109 12.3625 19.4 13.942 19.4 17.0875L19.4 19.45L0.499998 19.45L0.499999 17.0875ZM9.94978 15.0625C7.53329 15.0625 4.79279 15.967 3.65879 16.75L16.2408 16.7501C15.1068 15.967 12.3663 15.0625 9.94978 15.0625ZM11.975 5.27505C11.975 4.15455 11.0705 3.25005 9.95 3.25005C8.8295 3.25005 7.925 4.15455 7.925 5.27505C7.925 6.39555 8.8295 7.30005 9.95 7.30005C11.0705 7.30005 11.975 6.39555 11.975 5.27505ZM19.454 12.4436C21.02 13.5776 22.1 15.0896 22.1 17.0876L22.1 19.4501L27.5 19.4501L27.5 17.0876C27.5 14.3606 22.775 12.8081 19.454 12.4436ZM22.775 5.27505C22.775 7.88055 20.6555 10.0001 18.05 10.0001C17.321 10.0001 16.646 9.82455 16.025 9.52755C16.8755 8.32605 17.375 6.85455 17.375 5.27505C17.375 3.69555 16.8755 2.22405 16.025 1.02255C16.646 0.72555 17.321 0.55005 18.05 0.55005C20.6555 0.550051 22.775 2.66955 22.775 5.27505Z"
        fill={fill}
      />
    </IconBase>
  )
}
