import styled, { css } from "styled-components"
import {
  color,
  space,
  layout,
  border,
  background,
  position,
  typography,
  system,
} from "styled-system"

export const IconBase = styled.svg`
${typography}
${background}
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
    /* add custom props to styled-system */
  ${system({
    transform: true,
    transition: true,
  })}
    ${({ shouldScale }) =>
      shouldScale &&
      css`
        transform: scale(1);
        transition: transform 350ms ease;
        &:hover {
          transform: scale(1.2);
          transition: transform 350ms ease;
        }
      `}
  `
