import React from "react"
import propTypes from "prop-types"
import {
  Box,
  MobileWrap,
  CreateAccount,
} from "@/components/"

export const OptinModal = ({ closeModal, navigateTo, CTA, headerOne, headerTwo }) => {
  return (
    <Box
      width="100%"
      height={"100vh"}
      color={"dark"}
      css={{ position: "fixed", top: 0}}
    >
      <MobileWrap
        height="100%"
        display="flex"
        flexDirection={"column"}
        mx="auto"
      >
        <Box width={["100%", null, null, 5 / 12]} mx="auto" mt={["9rem", null, "15rem", null]} css={{zIndex: 3}}>
            <CreateAccount 
                headerOne={headerOne ? headerOne : "ENTER YOUR CONTACT DETAILS"}
                headerTwo={headerTwo ? headerTwo : "Claim your FREE Training"}
                cta={CTA ? CTA : "Send my video"}
                navigateTo={navigateTo ? navigateTo : "/freetraining/level"}
            />
          </Box>
      </MobileWrap>
      <Box
      width="100%"
      height={"100vh"}
      bg={("#5127AE")}
      css={{ position: "fixed", top: 0, opacity: .5}}
      onClick={closeModal ? closeModal : ()=>{} }
    ></Box>
    </Box>
  )
}

OptinModal.propTypes = {
    closeModal: propTypes.func
}