import { gql } from "graphql-request"

const mutation = gql`
  mutation CreateUser(
    $email: String!,
    $first_name: String!,
    $last_name: String!,
    $phone_number:String!,
    $password: String!
  ) {
    createUser(
        email: $email,
        firstName: $first_name,
        lastName: $last_name,
        phoneNumber: $phone_number,
        password: $password
    )
    { ok }
  }
`

export default mutation
