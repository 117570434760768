import React from "react"
import { IconBase } from "./IconBase"

export const MentorAlert = ({
  width = 37,
  height = 36,
  fill = "#5127AE",
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.887 13.71v5.215c0 .71-.582 1.292-1.293 1.292H11.67l-5.17 5.17V7.293C6.5 6.582 7.082 6 7.792 6h13.566a6.192 6.192 0 00-.169 2.584H9.084v10.56l1.512-1.513h12.705V12.4a6.17 6.17 0 002.586 1.311zm1.955.143v9.945l-1.773-1.772H11.594v1.587c0 .711.581 1.293 1.292 1.293h12.445l5.17 5.17V12.992a6.15 6.15 0 01-2.66.86z"
        fill="#5127AE"
      />
      <circle cx={27.125} cy={7.875} r={4.5} fill="#0BC3B8" />
    </IconBase>
  )
}
