import React from "react"
import { IconBase } from "./IconBase"

export const Instagram = ({
  width = 18,
  height = 18,
  fill = "#5127AE",
  shouldScale = true,
  ...rest
}) => {
  return (
    <IconBase
      width={width}
      height={height}
      shouldScale={shouldScale}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.002 4.385A4.607 4.607 0 004.388 9a4.607 4.607 0 004.614 4.615A4.607 4.607 0 0013.616 9a4.607 4.607 0 00-4.614-4.615zm0 7.615c-1.65 0-3-1.345-3-3s1.346-3 3-3 3 1.345 3 3-1.35 3-3 3zm5.879-7.804c0 .599-.482 1.077-1.076 1.077a1.076 1.076 0 111.076-1.076zm3.056 1.093c-.069-1.442-.398-2.72-1.454-3.772C15.431.465 14.154.136 12.713.063c-1.486-.084-5.94-.084-7.425 0-1.438.069-2.715.398-3.771 1.45C.46 2.566.136 3.843.063 5.285c-.084 1.486-.084 5.94 0 7.426.069 1.442.398 2.72 1.454 3.772 1.056 1.052 2.329 1.381 3.77 1.454 1.486.084 5.94.084 7.425 0 1.442-.069 2.719-.398 3.771-1.454 1.052-1.053 1.381-2.33 1.454-3.772.084-1.486.084-5.936 0-7.422zm-1.92 9.017a3.037 3.037 0 01-1.71 1.71c-1.185.47-3.996.362-5.305.362-1.31 0-4.124.105-5.305-.361a3.037 3.037 0 01-1.71-1.711c-.47-1.185-.362-3.997-.362-5.306 0-1.31-.104-4.125.362-5.306a3.037 3.037 0 011.71-1.71c1.185-.47 3.996-.362 5.305-.362 1.31 0 4.124-.105 5.305.361.787.313 1.393.92 1.71 1.711.47 1.185.362 3.997.362 5.306 0 1.31.108 4.125-.362 5.306z"
        fill={fill}
      />
    </IconBase>
  )
}
