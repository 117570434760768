import styled from "styled-components"
import { color, space, layout, typography, system } from "styled-system"
import { body, bodySmall, body2, body3, footer, subhead } from "@/theme/index"

export const Text = styled.p`

  ${({ level }) => (level === 1 || level === "1") && body}
  ${({ level }) => (level === 2 || level === "2") && body2}
  ${({ level }) => (level === 3 || level === "3") && body3}
  ${({ variant }) => variant === "small" && bodySmall}
  ${({ variant }) => variant === "footer" && footer}
  ${({ variant }) => variant === "subhead" && subhead}

  ${typography}
  ${space}
  ${color}
  ${layout}
  ${system({
    fontStyle: true,
    textTransform: true,
  })}


   a:hover {
    color: black;
   }


`

Text.defaultProps = {
  level: 1,
}

export const Span = styled.span`
  ${typography}
  ${space}
  ${color}
  ${layout}
  ${system({
    fontStyle: true,
  })}
`
