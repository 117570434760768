import React from "react"
import styled, { css } from "styled-components"
import {
  color,
  space,
  layout,
  border,
  typography,
  position,
} from "styled-system"
import {
  cta,
  primaryCTA,
  secondaryCTA,
  tertiaryCTA,
  specialCTA,
} from "@/theme/index"
import { Link as GatsbyLink } from "gatsby"

export const CTA = ({ to, as, children, variant, target, ...rest }) => (
  <StyledLink to={to} target={target} variant={variant} {...rest}>
    {children}
  </StyledLink>
)

const base = css`
  cursor: pointer;
  border-radius: 4px;
  padding: 1rem 2rem;
  min-width: 100px;
  border: 0;
  display: inline-block;
  margin: 0;
  text-decoration: none!important;

  ${typography}
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${cta}

  &:active,
  &:focus,
  &:hover,
  &:visited {
    border-radius: 4px;
  }
  `

// extend Link
const StyledLink = styled(GatsbyLink).attrs(props => ({
  target: props.target ? props.target : "_self", // Default to "_self" if no target prop is provided
  rel: props.target === "_blank" ? "noopener noreferrer" : undefined,
}))`
    ${base}

    ${({ variant }) => variant === "primary" && primaryCTA}
    ${({ variant }) => variant === "secondary" && secondaryCTA}
    ${({ variant }) => variant === "tertiary" && tertiaryCTA}
    ${({ variant }) => variant === "special" && specialCTA}
`

StyledLink.defaultProps = {
  variant: "primary",
}
