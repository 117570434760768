import * as Yup from "yup"

const phoneRegExp = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i

// validate fields and provide errors.
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  // last_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please provide a valid email address")
    .required("This field is required"),
  phone_number: Yup.string().matches(phoneRegExp, "Phone number is not valid")
  .required("This field is required"),
  // skill_level: Yup.string()
  //   .oneOf(["Beginner", "Intermediate", "Advanced"], "Invalid skill level"),
})

export default validationSchema
