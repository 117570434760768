import React from "react"
import {
  Heading,
  Text,
  CTA,
  Box,
  Width,
  LessonMap,
  SmallWave,
  Mastery,
  MentorAlert,
  CalComplete,
  CalIncomplete,
  ArrowAlt,
  VideoPlayer,
  Slide,
  Carousel,
  ListItem,
  List,
} from "@/components/index"
import { color } from "@/theme/index"
import Img from "gatsby-image"
import styled from "styled-components"

export const MentorFooter = ({ data, name }) => {
  let nextMonday = new Date()
  nextMonday.setDate(nextMonday.getDate() + ((1 + 14 - nextMonday.getDay()) % 14))
  const nextMondayDate =
    nextMonday.getMonth() +
    1 +
    "/" +
    nextMonday.getDate() +
    "/" +
    nextMonday.getFullYear()
  return (
    <>
      <Box
        bg={color.tourmaline}
        backgroundImage={color.gradient}
        py={["3rem", "6rem", null, null]}
        variant="slopeTopLeft"
      >
        {/* Accelerated Learning */}
        <Width pt="3.6rem" pb="6rem" background>
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            mb="5rem"
            textAlign="center"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading level="1" mb="3.6rem" color="white">
              Sonora Guitar Intensive
            </Heading>
            <Text mt="3.6rem" mb="1.5em" color="white">
              Made for intermediate guitar players, Sonora is a 3-month
              intensive program that helps you break through multi-year plateaus
              and find your creative freedom.
            </Text>
            <Img
              alt="Accelerated Learning"
              fluid={data.levelupImage.childImageSharp.fluid}
            />
          </Box>
          <Box display={["block", null, "flex", null]}>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              p="2.4rem"
              borderRadius=".6rem"
              textAlign="center"
              mb={["2.4rem", null, "0"]}
              bg="#FFF7F4"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, "flex-start"]}
              >
                <Mastery mr="0.8rem" />
                <Text fontWeight="bold" color="amethyst">
                  Cutting Edge Software
                </Text>
              </Box>
              <Text level={2}>
                Absorb, polish, and retain core concepts through accelerated
                learning and language acquisition techniques.
              </Text>
            </Box>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={["2.4rem", null, "0"]}
              bg={color.opal}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, "flex-start"]}
              >
                <LessonMap mr="0.5rem" />
                <Text fontWeight="bold" color="amethyst">
                  LIVE Masterclasses
                </Text>
              </Box>
              <Text level={2}>
                Learn and engage with with industry-leading musicians 5 days per
                week in our live classes.
              </Text>
            </Box>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              p="2.4rem"
              textAlign="center"
              bg={color.agate}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, 0, null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={["center", null, "flex-start"]}
              >
                <MentorAlert mr="0.8rem" />
                <Text fontWeight="bold" color="amethyst">
                  Streamlined feedback
                </Text>
              </Box>
              <Text level={2}>
                Chat with your mentor in real-time. You’ll also get detailed
                feedback when you share videos of your guitar playing.
              </Text>
            </Box>
          </Box>
          <Box width="100%" mx="auto" textAlign="center">
            <CTA to="/application" variant="tertiary" mt="3.6rem">
              Apply Now
            </CTA>
          </Box>
        </Width>
      </Box>
      <Box
        bg={color.white}
        py={["3rem", "6rem", null, null]}
        variant="slopeTopLeft"
      >
        {/* Modern Study */}
        <Width pt="3.6rem" pb="6rem">
          <Box
            width={["100%", 10 / 12, 8 / 12, 6 / 12]}
            mx="auto"
            mb="5rem"
            textAlign="center"
          >
            <SmallWave display="block" mx="auto" mb="1.2rem" />
            <Heading level="2" mb="2rem">
              A modern approach to study
            </Heading>
            <Text>
              Unlike private lessons, Sonora's Fellowship students get unlimited
              access to feedback so learning and progress can happen every day –
              not just once a week.
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection={["column", null, "row", null]}
            mx="auto"
          >
            <Box
              width={["100%", 10 / 12, 5 / 12, null]}
              textAlign="center"
              mx="auto"
            >
              <Text
                fontSize="14px"
                fontWeight="bold"
                color={color.tanzanite}
                mb=".6rem"
              >
                THE OLD WAY
              </Text>
              <Text as="h3" color="amethyst" mb="0.6rem">
                Traditional weekly lessons
              </Text>
              <Box display="flex" justifyContent="center" pt=".6rem" pb="3rem">
                <CalComplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
                <CalIncomplete />
              </Box>
              <Text level="2">
                Students met with an instructor for just an hour, once a week.
                For the remainer of that week, students were more likely to
                practice bad habits and progress slowly.
              </Text>
            </Box>
            <Box
              width={["100%", null, 2 / 12, null]}
              display="flex"
              alignItems="center"
              mx={["0", null, "3.6rem", null]}
              mt={["3.6rem", null, 0, null]}
              mb={["2.4rem", null, "0", null]}
            >
              <ArrowAlt
                // eslint-disable-next-line
                transform={["rotate(90deg)", null, "rotate(0deg)"]}
                mx="auto"
                stroke="#B3A8CB"
              />
            </Box>
            <Box
              width={["100%", 10 / 12, 5 / 12, null]}
              textAlign="center"
              mx="auto"
            >
              <Text
                fontSize="14px"
                fontWeight="bold"
                color={color.tanzanite}
                mb=".6rem"
              >
                THE MODERN WAY
              </Text>
              <Text as="h3" color="amethyst" mb="0.6rem">
                Sonora's anytime framework
              </Text>
              <Box display="flex" justifyContent="center" pt=".6rem" pb="3rem">
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
                <CalComplete />
              </Box>
              <Text level="2">
                Students get unlimited access to a mentor, 7 days a week. Along
                with a custom daily practice plan, forward progress can happen
                rapidly and at any time.
              </Text>
            </Box>
          </Box>
          <Box width="100%" mx="auto" textAlign="center">
            <CTA to="/application" variation="primary" mt="3.6rem">
              Apply Now
            </CTA>
          </Box>
        </Width>
      </Box>

      {/* Curriculum */}
      <Box bg={color.amethyst} variant="slopeBottomLeft" pt="6rem">
        <Width
          display={["block", null, "flex", null]}
          justifyContent="space-between"
          color={color.white}
        >
          <Box width={["100%", null, 5 / 12, null]}>
            <SmallWave
              display="block"
              mb="1.2rem"
              mx={["auto", null, 0, null]}
            />
            <Heading
              level={2}
              mb="2rem"
              textAlign={["center", null, "left", null]}
              color={color.thulite}
            >
              A clear path to <br />
              fretboard fluency
            </Heading>
            <Text
              mb="2.4rem"
              textAlign={["center", null, "left", null]}
              width={["100%", "80%", "100%", null]}
              mx={["auto", null, 0, null]}
            >
              Our curriculum helps you achieve a complete understanding of the
              foundations of music, <em>regardless of your playing style</em>.
            </Text>
            <Box
              maxWidth={["30rem", null, "80%", null]}
              mx={["auto", null, 0, null]}
              mb={["3.6rem", null, 0, null]}
            >
              <Img
                fluid={data.curriculumImage.childImageSharp.fluid}
                alt="Curriculum Mastery"
              />
            </Box>
          </Box>
          <Box
            width={["100%", "80%", 6 / 12, null]}
            mx={["auto", null, 0, null]}
            textAlign={["center", null, "left", null]}
          >
            <Heading as="h4" level={3} color={color.thulite}>
              Over 20 years experience
            </Heading>
            <Text level={2} mb="3.6rem">
              Developed by deeply experienced music educators, our comprehensive
              curriculum identifies the key skills required to advance your
              musicianship.
            </Text>
            <Heading as="h4" level={3} color={color.thulite}>
              Successfully tested by thousands
            </Heading>
            <Text level={2} mb="3.6rem">
              <em>
                <u>Our curriculum is proven.</u>
              </em>{" "}
              Over a thousand Sonora students have successfully broken through
              their learning plateaus and unlocked fretboard fluency.
            </Text>
            <Heading as="h4" level={3} color={color.thulite}>
              Years of study condensed into months
            </Heading>
            <Text level={2}>
              Our students make more progress in 3-6 months of intensive study
              in our program than most students make in several years.
            </Text>
          </Box>
        </Width>
        <Box width="100%" mx="auto" textAlign="center">
          <CTA to="/application" variant="tertiary" mt="3.6rem">
            Apply Now
          </CTA>
        </Box>
      </Box>

      {/* Grow With Our Community */}

      <Width pt="3.6rem">
        <Box
          width={["100%", 10 / 12, 8 / 12, 6 / 12]}
          mx="auto"
          textAlign="center"
          variant="slopeBottomRight"
        >
          <SmallWave display="block" mx="auto" mb="1.2rem" />
          <Heading level="2" mb="2rem">
            Grow with our community
          </Heading>
          <Text>
            Sonora connects you with other intermediate guitar players pursuing
            creative fluency.
          </Text>
          <Box my="3.6rem">
            <Img
              fluid={data.communityImage.childImageSharp.fluid}
              alt="Grow With Our Community"
            />
          </Box>
          <Text level="2">
            Our community is a great place to find practice buddies, share
            progress, get feedback, and cheer each other on — all with the
            support of the whole Sonora team.
          </Text>
          <CTA to="/application" variation="primary" mt="3.6rem">
            Apply Now
          </CTA>
        </Box>
      </Width>
      {/* Programs */}
      <Box
        color="white"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        backgroundImage="linear-gradient(79.88deg, #1B0646 27.59%, #5128AE 100.28%)"
        pt={["12.5rem", null, null, "7rem"]}
        pb={["4.8rem", null, null, "10rem"]}
        variant="slopeTopLeft"
      >
        <Box
          width={["100%", 10 / 12, 8 / 12, 6 / 12]}
          mx="auto"
          mb="5rem"
          textAlign="center"
        >
          <SmallWave display="block" mx="auto" mb="1.2rem" />
          <Heading mb="3.6rem">Our Program</Heading>
          <Text mt="3.6rem">
            Join our industry leading guitar intensive program with {name}{" "}
            guiding you every step of the way.
          </Text>
        </Box>
        <Width
          display="flex"
          flexDirection={["column", null, "row", null]}
          textAlign="left"
          alignItems="center"
          flex="1"
        >
          <Box
            mx="auto"
            backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
            height="100%"
            borderRadius=".8rem"
            width={["100%", "80%", 6 / 12, null]}
          >
            <Box
              width={"100%"}
              borderRadius=".6rem"
              textAlign="center"
              mb={["0rem", null, "0"]}
              bg={color.opal}
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                width={"100%"}
                p="2.4rem"
                borderRadius=".6rem"
                textAlign="center"
                mb={"0rem"}
              >
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  color={color.amethyst}
                  mb=".6rem"
                  textAlign="center"
                >
                  BY APPLICATION ONLY
                </Text>
                <Heading
                  mb=".8rem"
                  mx={["auto", 0, null, null]}
                  color={color.tanzanite}
                  textAlign="center"
                >
                  Sonora Guitar Intensive
                </Heading>
                <Text
                  textAlign="center"
                  color={color.tanzanite}
                  fontSize="19px"
                  mb="2.3rem"
                >
                  Included with the program:
                </Text>
                <Box textAlign="left">
                  <List bullets="checkmark">
                    <ListItem color="tanzanite">
                      Unlimited access to {name}
                    </ListItem>
                    <ListItem color="tanzanite">
                      1-on-1 lessons w/ {name}*
                    </ListItem>
                    <ListItem color="tanzanite">
                      Daily video feedback on your playing
                    </ListItem>
                    <ListItem color="tanzanite">Custom practice plans</ListItem>
                    <ListItem color="tanzanite">
                      Membership to our community
                    </ListItem>
                    <ListItem color="tanzanite">
                      Lifetime access to our core curriculum
                    </ListItem>
                    <ListItem color="tanzanite">
                      Lifetime access to our learning software
                    </ListItem>
                    
                  </List>
                </Box>
                <CTA variant="special" to="/application" mb="1.2rem" mt="3rem">
                  Apply Now
                </CTA>
                <Box width={["100%", null, null, 7 / 12]} mx="auto">
                  <Text color="tanzanite" fontStyle="italic" variant="small">
                    *Pricing depends on duration of study and lesson frequency,
                    apply for a quote.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Width>
        <Box
          width={["80%", "80%", 6 / 12, 5 / 12]}
          p="2.4rem"
          bg="#FFF7F4"
          borderRadius=".6rem"
          mt="2em"
          mx="auto"
        >
          <Box
            display="flex"
            mb="0.6rem"
            justifyContent="space-between"
            flexDirection={["column", null, "row", null]}
          >
            <Box
              width={["100%", "80%", null, 8 / 12]}
              textAlign={["center", null, "left", null]}
              mb={["1em", null, "0em", null]}
            >
              <Text fontWeight="bold" color="amethyst">
                Not quite intermediate yet?
              </Text>
              <Text level={2} color="tanzanite">
                Check out our Prep Program for beginners
              </Text>
            </Box>
            <Box
              width={["100%", "80%", 4 / 12, null]}
              display="flex"
              alignItems="center"
              justifyContent={["center", null, "flex-end", null]}
            >
              <CTA to="/prep" variant="primary">
                Learn More
              </CTA>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`