import create from "zustand"
import produce from "immer"

const useStore = create((set, get) => ({
  user: {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  },
  plan: null,
  token: null,
  switchPlan: plan => set(state => ({ plan })),
  setToken: token => set(token => ({ token })),
  updateUser: fn => set(produce(fn)),
}))

export const paymentInfo = {
  1: {
    name: "installments",
    priceId: process.env.GATSBY_STRIPE_PRICE_ID_MONTHLY,
    iterationCount: 5,
    amount: `$595 x 5 <br/>billed monthly`,
    smallPrint: `<strong>After any free trial(s) and/or discount(s), you will be charged 5 payments of $595 plus applicable taxes, in monthly installments</strong>. If you do not cancel during the free trial period, you will be charged. If applicable, your subscription(s) will continue until you cancel. To cancel, login to Sonora, navigate to your account and manage your billing preferences. By selecting ‘Pay now,’  you agree to the above terms. `,
  },
  2: {
    name: "upfront",
    priceId: process.env.GATSBY_STRIPE_PRICE_ID_UPFRONT,
    iterationCount: 1,
    amount: `$1975/once`,
    smallPrint: `<strong>After any free trial(s) and/or discount(s), you will be charged a one-time fee of $1975 plus applicable taxes</strong>. If you do not cancel during the free trial period, you will be charged. If applicable, your subscription(s) will continue until you cancel. To cancel, login to Sonora, navigate to your account and manage your billing preferences. By selecting ‘Pay now,’  you agree to the above terms. `,
  },
}

export default useStore
