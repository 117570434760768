import { GraphQLClient } from "graphql-request"

import apiUrl from "./apiUrl"
import updatePaymentMethodMutation from "@/graphql/updatePaymentMethod"
import localStorage from "@/utils/localStorage"

const updatePaymentMethod = async token => {
  const authToken = localStorage.get("authToken")

  const authHeaders = {
    headers: {
      authorization: `JWT ${authToken}`,
    },
  }

  const graphQLClient = new GraphQLClient(apiUrl, authHeaders)

  const variables = {
    token,
  }

  const data = await graphQLClient.request(
    updatePaymentMethodMutation,
    variables
  )
  return data
}

export default updatePaymentMethod
